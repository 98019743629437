(function ($, _, Drupal, generic, prodcat) {
  Drupal.behaviors.productHairQuizMatch = {
    attach: function (context) {
      // The backend gets confused if you make 2 queries to the same method at
      // the same time. We don't need this on the hair quiz page though.
      if (Drupal.behaviors.hairQuiz) {
        return;
      }

      this.$els = $(this.$els).add($('.js-product-hair-quiz-match', context));

      if (!this.matches) {
        this.fetch().always(this.applyMatches.bind(this));
      }
    },

    matches: null,

    fetch: function () {
      var self = this;
      var deferred = $.Deferred();

      generic.jsonrpc.fetch({
        method: 'quiz.get_answers_and_results',
        params: [{ type: 'hairAdvisor' }],
        onSuccess: function (jsonRpcResponse) {
          var response = jsonRpcResponse.getValue();

          self.matches = Object.keys(response.results || {})
            .filter(function (key) {
              return Array.isArray(response.results[key]);
            })
            .reduce(function (acc, key) {
              return acc.concat(response.results[key] || []);
            }, [])
            .map(function (skuId) {
              return (prodcat.data.getSku(skuId) || {}).PRODUCT_ID;
            })
            .filter(_.identity);

          deferred.resolve();
        },
        onFailure: function (err) {
          deferred.reject(err);
        }
      });

      return deferred.promise();
    },

    isMatch: function (productId) {
      return Boolean(this.matches && this.matches.indexOf(productId) >= 0);
    },

    applyMatches: function () {
      var self = this;

      this.$els.each(function () {
        $(this).toggle(self.isMatch($(this).data('product-id')));
      });
    }
  };
})(window.jQuery, window._, window.Drupal, window.generic, window.prodcat);
